module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"B2B Ninja","short_name":"B2BNinja","start_url":"/","background_color":"#0D52FF","theme_color":"#0D52FF","display":"minimal-ui","icon":"static/favicon.svg","crossOrigin":"use-credentials","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.b2bninja.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManagerOptions":true,"iubendaOptions":{"gdprAppliesGlobally":false,"enableCcpa":true,"countryDetection":true,"consentOnContinuedBrowsing":false,"perPurposeConsent":true,"ccpaAcknowledgeOnDisplay":true,"lang":"en","siteId":2273013,"cookiePolicyId":12980509,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":true,"acceptButtonColor":"#0D52FF","acceptButtonCaptionColor":"white","customizeButtonColor":"#DADADA","customizeButtonCaptionColor":"#4D4D4D","rejectButtonDisplay":true,"rejectButtonColor":"#0D52FF","rejectButtonCaptionColor":"white","position":"float-top-center","textColor":"black","backgroundColor":"white"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"sxv808u4","include_in_development":true,"delay_timeout":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
